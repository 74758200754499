<template>
  <div>
    <search-service-order-death @submit="submitSearch" />
    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <span slot="name" slot-scope="text, record">
        <a-space>
          <a @click="showModal(record)">{{ record.name }}</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
    <!-- 详情窗口 -->
    <show-death-info
      v-if="isShowModal"
      :visible.sync="isShowModal"
      :record="showingRecord"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findTenantServiceOrderDeathList } from '@/api/service_order_death'
import { hasPermission } from '@/utils/permission'
import SearchServiceOrderDeath from '@/views/service_order_deaths/tenant/Search'

export default {
  name: 'DeathsList',
  components: {
    Pagination,
    SearchServiceOrderDeath,
    ShowDeathInfo: () => import('@/views/service_order_deaths/tenant/show/index')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      showingRecord: {}, // 正在编辑的记录id
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '姓名',
          dataIndex: 'name',
          width: 200,
          fixed: 'left',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '性别',
          width: 100,
          dataIndex: 'sex'
        },
        {
          title: '年龄',
          width: 50,
          dataIndex: 'age'
        },
        {
          title: '逝世时间',
          width: 150,
          dataIndex: 'death_date'
        },
        {
          title: '关联订单号',
          width: 150,
          dataIndex: 'order_no'
        },
        {
          title: '订单状态',
          width: 150,
          dataIndex: 'order_status'
        },
        {
          title: '厅房名称',
          width: 100,
          dataIndex: 'hall_name'
        }
      ]
    },

    isHasEditPermission() {
      return true
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },
    showModal(record) {
      this.showingRecord = record
      this.isShowModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantServiceOrderDeathList(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
